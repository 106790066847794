import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import store from "@/store";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

export default function authFunctions(){

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const router = useRouter()
    const homePages = {
        'is-advisor': '/home',
        'is-admin': '/admin/dashboard',
        'is-ap-agent': '/ap_agent/home',
        'is-company': '/company/home',
    }

    const doLogin = async (user_email, user_pwd) => {

        let toast = await toastController.create({duration: 3000, color: 'danger', position: 'top'})
        if(!user_email.length || !user_pwd.length){
            toast.message = i18n.t(!user_email.length ? 'forgot_password.empty_email': 'login_page.enter_password')
            await toast.present()
            return false
        }

        let loader = await loadingController.create({message: i18n.t('Signing In...')})
        await loader.present()

        let data = {
            grant_type: 'password',
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
            username: user_email,
            password: user_pwd,
            locale: store.state.appConfig.lang,
        }
        store.dispatch("auth/auth_request", data, {root: true})
            .then( (resp) => {
                router.push( resp.data.role ? homePages[resp.data.role] || '/' : '/')
            })
            .catch( (err) => {
                let message
                if(err.response.status === 400){
                    message = i18n.t( err.response.data.hint ? err.response.data.hint : 'Invalid credentials')
                }
                else{
                    message = err
                }
                alertController.create({
                    cssClass: isDesktop ? 'alert-web' : '',
                    header: i18n.t('Login failed'), message: message,
                    buttons: [
                        {
                            text: i18n.t('OK'),
                            cssClass: isDesktop ? 'alert-btn-light' : '',
                        }
                    ]
                }).then((alert) => alert.present())
            })
            .then(() => loader.dismiss() )
    }

    return{
        doLogin,
    }
}